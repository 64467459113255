header {
    button.MuiButtonBase-root{
        border: 1px solid #003152;
    }
    button.MuiButtonBase-root:hover{
        background-color: #c9caca;
    }
    
    .header__profile{
        background-color: #003152;
    }
   .navbar-nav.attr-nav li .nav-link {
        margin-top: 7px;
    }
    .search-field{
        border-radius: 100% !important;
    }
    
}