.editProfile {
  max-width: 600px;
  background: #FFFFFF;
  border-radius: 8px;

  .editProfile__head {
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
    }
  }

  .editProfile__content {
    .editProfile__details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      padding: 15px 30px;
    }

    p {

      color: #A8A8A8;
      margin: 0;
      font-size: 14px;

      span {
        // color: #239EFE;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .editProfile__details__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;

      .editProfile__details__query {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        width: 50%;

        .MuiGrid-item {
          width: 100%;
        }

        select {
          width: 100%;
          padding: 5px;
          border: 1px solid #B9B9B9;
          border-radius: 4px;
          font-size: 16px;
        }

        Input {
          padding: 8px;
        }
      }

    }

    .editProfile__buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      width: 90%;
      padding: 15px;

      .editProfile__cancelbtn {
        padding: 10px;
        font-size: 14px;
        background: #FFFFFF;
        color: #1A1A1A;
        border-radius: 4px;
        border: none;
        cursor: pointer;
      }

      .editProfile__savebtn {
        padding: 10px 15px;
        font-size: 14px;
        color: #FFFFFF;
        background: #239EFE;
        border-radius: 4px;
        outline: none;
        border: none;
        cursor: pointer;
      }
    }
  }

  Input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}