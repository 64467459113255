html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  color: #2d2323;
  font-family: -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetical Neue',
    sans-serif;
}

.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}