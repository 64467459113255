.profileInfo {
    height: max-content;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .profileInfo__content {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;

        .profileInfo__contenthead {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .profileInfo__profile {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 5px;

                h4 {
                    // color: #1A1A1A;
                    margin: 0;
                }

                h6 {
                    // color: #A1A1A1;
                    margin: 0;
                }
            }

            .profileInfo__edit {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                background: #239EFE;
                gap: 5px;
                border-radius: 5px;
                padding: 5px;
                cursor: pointer;

                svg {
                    color: #FFFFFF;
                }

                h6 {
                    color: #FFFFFF;
                    margin: 0;
                }
            }
        }

        .profileInfo__seperator {
            border: 1px solid #DDDDDD;
        }

        .profileInfo__name {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .profileInfo__fields__content {
                // display: flex;
                // flex-direction: row;
                // justify-content: space-between;
                // align-items: center;
                // width: 400px;

                h6 {
                    // color: #A1A1A1;
                    margin: 0;
                    font-size: 14px;
                }

                p {
                    font-weight: 500;
                    color: #687188;
                    margin: 0;
                    font-size: 14px;
                }
            }
        }

        .profileInfo__fields {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .profileInfo__fields__content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 200px;

                h6 {
                    // color: #A1A1A1;
                    margin: 0;
                    font-size: 14px;
                }

                .profileInfo__add {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;

                    h6 {
                        color: #239EFE;
                        margin: 0;
                        font-size: 14px;
                    }

                    svg {
                        color: #239EFE;
                    }
                }
            }
        }
    }
}