.auth {
  display: flex;
  flex-direction: column;
  padding: 20px;
  // max-width: 500px;

  .auth__switchMode {
    p {
      color: #191D23;
      font-size: 16px;

      span {
        color: #043263;
        font-weight: bold;
      }
    }
  }

  .auth__main {

    .auth__name,
    .auth__pwd {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }

    .auth__name>div {
      width: 50%;
    }

    .auth__level {
      align-items: center;
      gap: 10px;
    }


  }


  .auth__otp {
    display: flex;
    flex-direction: column;

    .auth__otpGoBack {
      width: max-content;
      padding: 0;
      margin-bottom: 30px;

      svg {
        color: #0863C4;
      }
    }

  }

  span,
  p {
    color: #7A7A7A;
  }

  .MuiGrid-item {
    box-shadow: 0px 0px 24px 4px rgb(0 0 0 / 5%);
    margin: 10px 0;
    margin-top: 5px;
    font-size: 14px;
    outline-width: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  .auth__email {
    background: #003152;
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: #ffffff;
    padding: 15px;
    border: none;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }

  .auth__email:hover {
    background-color: #156092
  }

  button:disabled,
  button[disabled] {
    background: #CEE5FD;
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: #A4CEFB;
    border: none;
  }

  .auth__divider {
    text-align: center;
    color: #7A7A7A;
    margin-bottom: 0px;
  }

  .auth__google {
    border: 1px solid #DEDEDE;
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background-color: #ffffff;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    cursor: pointer;
    width: 100%;
  }

  p {
    font-size: 14px;

    span {
      color: #3B96F7;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 380px) {

  .auth .auth__main .auth__name,
  .auth .auth__main .auth__pwd {
    display: unset;
  }

  .auth__name>div {
    width: 100% !important;
  }
}





// User Button
.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-dashed {
  border-style: dashed;
}

.border {
  border-width: 1px;
}

.rounded-md {
  // border-radius: 0.375rem;
  border-radius: 30px;
}

.w-60 {
  width: 15rem;
}

.flex {
  display: flex;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.flex-1 {
  flex: 1 1 0%;
}


.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.font-medium {
  font-weight: 500;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
}

.border-transparent {
  border-color: transparent;
}

.border-0 {
  border-width: 0px;
}


.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

.block {
  display: block;
}